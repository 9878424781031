/** @jsx jsx */
import { Link } from "gatsby";
import { jsx } from "theme-ui";
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes";
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config";
import { useMediaQuery } from "react-responsive";

const HeaderTitle = () => {
    const { siteTitle } = useSiteMetadata();
    const { basePath } = useMinimalBlogConfig();
    const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

    return (
        <Link
            to={replaceSlashes(`/${basePath}`)}
            aria-label={`${siteTitle} - Back to home`}
            sx={{ color: `heading`, textDecoration: `none` }}
        >
            <div style={{ display: "flex" }}>
                <img src="/sequence_software_logo_100.png" width={isMobile ? "38" : "50"} height={isMobile ? "38" : "50"} />
                <h1 sx={{ my: 0, marginLeft: "10px", fontWeight: `medium`, fontSize: [3, 4] }}>
                    {siteTitle}
                </h1>
            </div>
        </Link>
    );
};

export default HeaderTitle;
